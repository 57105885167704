<template>
  <v-container style="max-width:1200px;">
    <v-row justify="center">
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="11"
        lg="11"
        xl="8"
        :class="{ 'pa-2': $vuetify.breakpoint.smAndDown }"
      >
        <v-card flat style="border: 1px solid #AD145733;">
          <v-card-title
            class="py-1 px-4 pl-2"
            style="background-color:transparent;color:#AD1457;border-bottom: 2px solid #AD1457;"
          >
            <v-btn icon color="#AD1457" @click="goBack" class="mr-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            Một lượt thuê phòng
            <v-spacer />
            <v-btn
              v-if="alllowCancel"
              class="text-none"
              dark
              @click="callCancelSession"
              style="height:32px;"
              color="red"
            >
              Hủy thuê
            </v-btn>
            <v-btn
              v-if="allowApprove"
              class="text-none ml-2"
              dark
              @click="callApproveSession"
              style="height:32px;"
              color="teal"
            >
              Phê Duyệt
            </v-btn>
            <v-btn
              v-if="allowReject"
              class="text-none ml-2"
              dark
              @click="callRejectSession"
              style="height:32px;"
              color="red"
            >
              Từ chối
            </v-btn>
          </v-card-title>

          <!-- Error Message -->
          <div v-if="errorMessage" style="color:red;" class="py-1">
            {{ errorMessage }}
          </div>

          <v-card-text
            v-if="session"
            class="py-2 pt-2"
            :class="{ 'px-2': $vuetify.breakpoint.smAndDown }"
          >
            <!-- Select a Room -->
            <div class="mt-3">
              <div class="" style="display:inline-block;min-width:90px;">
                Phòng:
              </div>
              <div
                style="display:inline-block;min-width: 200px;font-size:16px;"
              >
                <b>{{ session.properties.map((p) => p.name).join(", ") }}</b>
              </div>
            </div>

            <!-- Select A Time Range -->
            <div class="mt-3">
              <!-- startDate -->
              <div
                class="mr-4"
                :class="{ 'rent-inline-block': $vuetify.breakpoint.mdAndUp }"
              >
                <div style="display:inline-block;min-width:90px;">Bắt đầu:</div>
                <div style="display:inline-block;">
                  <b>{{
                    $moment(session.start_time).format("HH:mm, DD/MM/YYYY")
                  }}</b>
                </div>
              </div>
              <!-- endDate -->
              <div
                class="py-1"
                :class="{ 'rent-inline-block': $vuetify.breakpoint.mdAndUp }"
              >
                <div style="display:inline-block;min-width:90px;">
                  Kết thúc:
                </div>
                <div style="display:inline-block;">
                  <b>{{
                    $moment(session.end_time).format("HH:mm, DD/MM/YYYY")
                  }}</b>
                </div>
              </div>
              <!-- Số giờ thuê -->
              <div class="py-1">
                <div style="display:inline-block;min-width:90px;">
                  Số giờ thuê:
                </div>
                <div style="display:inline-block;font-size:16px;">
                  <b>{{ numOfHours }}</b>
                </div>
              </div>
            </div>

            <!-- Người thuê -->
            <div class="py-1">
              <div style="display:inline-block;min-width:90px;">
                Người thuê:
              </div>
              <div style="display:inline-block;font-size:16px;">
                <b>{{ session.fullname }}</b>
              </div>
            </div>

            <!-- Trạng thái -->
            <div class="py-1">
              <div style="display:inline-block;min-width:90px;">
                Trạng thái:
              </div>
              <div style="display:inline-block;font-size:16px;">
                <v-chip dark small :color="getStatusColor(session.status)">
                  <b>{{ getStatusName(session.status) }}</b>
                </v-chip>
              </div>
            </div>

            <div class="py-1" v-if="session.status == 'CANCELLED'">
              <div style="display:inline-block;min-width:90px;">Ngày hủy:</div>
              <div style="display:inline-block;font-size:14px;">
                <b>{{
                  $moment(session.cancelled_at).format("HH:mm, DD/MM/YYYY")
                }}</b>
              </div>
            </div>

            <div class="py-1" v-if="session.status == 'APPROVED'">
              <div style="display:inline-block;min-width:90px;">
                Ngày duyệt:
              </div>
              <div style="display:inline-block;font-size:14px;">
                <b>{{
                  $moment(session.confirmed_at).format("HH:mm, DD/MM/YYYY")
                }}</b>
              </div>
            </div>

            <div class="py-1" v-if="session.status == 'REJECTED'">
              <div style="display:inline-block;min-width:90px;">
                Ngày từ chối:
              </div>
              <div style="display:inline-block;font-size:14px;">
                <b>{{
                  $moment(session.rejected_at).format("HH:mm, DD/MM/YYYY")
                }}</b>
              </div>
            </div>

            <!-- Rejected Reason -->
            <div v-if="session.status == 'REJECTED'" class="mt-3">
              <div style="display:inline-block;min-width:90px;">
                Lý do từ chối:
              </div>
              <div style="display:inline-block;font-size:16px;">
                <p v-html="session.rejected_reason"></p>
              </div>
            </div>

            <!-- Inviter -->
            <div class="mt-3">
              <div class="mr-4" style="display:inline-block;min-width:90px;">
                Danh sách khách mời:
              </div>
              <template v-for="(name, idx) in session.inviters_name">
                <div
                  :key="'invter' + idx"
                  style="display:inline-block;font-size:16px;"
                >
                  <b>{{ name }}</b>
                  <span v-if="idx < session.inviters_name.length -1 "
                   class="mr-2">,</span>
                </div>
              </template>
            </div>

            <!-- Description -->
            <div class="mt-3">
              <div style="display:inline-block;min-width:90px;">Ghi chú:</div>
              <div style="display:inline-block;font-size:16px;">
                <p v-html="session.description"></p>
              </div>
            </div>
          </v-card-text>

          <v-card-actions class="px-4">
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      userId: "userId",
      token: "token",
      role: "role",
      fullname: "getFullName",
    }),
    isAdminMode: function() {
      return this.$root.nhanSuRoleUser.includes(this.userId);
    },
    isMyRentSession: function() {
      if (this.session && this.session.user_id == this.userId) {
        return true;
      }
      return false;
    },
    alllowCancel: function() {
      if (
        this.isMyRentSession &&
        new Date(this.session.start_time).getTime() > new Date().getTime() &&
        ["WAIT_FOR_APPROVE", "APPROVED"].includes(this.session.status)
      ) {
        return true;
      }
      return false;
    },
    allowApprove: function() {
      if (
        !this.isMyRentSession &&
        this.isAdminMode &&
        this.session &&
        new Date(this.session.start_time).getTime() > new Date().getTime() &&
        ["WAIT_FOR_APPROVE"].includes(this.session.status)
      ) {
        return true;
      }
      return false;
    },
    allowReject: function() {
      if (
        !this.isMyRentSession &&
        this.isAdminMode &&
        this.session &&
        new Date(this.session.start_time).getTime() > new Date().getTime() &&
        ["WAIT_FOR_APPROVE", "APPROVED"].includes(this.session.status)
      ) {
        return true;
      }
      return false;
    },
    numOfHours: function() {
      if (this.session) {
        return (
          this.$moment(this.session.end_time).diff(
            this.$moment(this.session.start_time),
            "minutes"
          ) / 60
        );
      }
      return 0;
    },
  },
  data() {
    return {
      session: null,
      users: [],
      errorMessage: "",
      statusList: [
        {
          selected: true,
          code: "WAIT_FOR_APPROVE",
          label: "Chờ duyệt",
          color: "orange",
        },
        { selected: true, code: "APPROVED", label: "Đã duyệt", color: "green" },
        {
          selected: false,
          code: "REJECTED",
          label: "Đã từ chối",
          color: "red",
        },
        {
          selected: false,
          code: "CANCELLED",
          label: "Đã hủy",
          color: "#888888",
        },
        { selected: false, code: "COMPLETED", label: "Hoàn thành", color:'#4A148C'}
      ],
    };
  },
  watch: {
    users() {
      this.processAfterFetchData();
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getStatusName(code) {
      let matchStatus = this.statusList.find((status) => status.code == code);
      if (matchStatus) return matchStatus.label;
      return "";
    },
    getStatusColor(code) {
      let matchStatus = this.statusList.find((status) => status.code == code);
      if (matchStatus) return matchStatus.color;
      return "black";
    },
    processAfterFetchData() {
      let self = this;
      if (self.users.length > 0) {
        // Set Fullname
        if (self.session.user_id == self.userId) {
          self.session.fullname = self.fullname;
        } else {
          let user = self.users.find((u) => u._id == self.session.user_id);
          if (!user) self.session.fullname = "";
          self.session.fullname = user.fullname;
        }

        // Set Inviter Name
        self.session.inviters_name = self.users
          .filter((u) => self.session.inviters_id.includes(u._id))
          .map((u) => u.fullname);
      }
      self.$forceUpdate();
    },
    fetchRentSession() {
      if (!this.$route.params.session_id) {
        return;
      }
      let sessionId = this.$route.params.session_id;
      let self = this;

      this.axios
        .get(self.$root.apiAssetMana + `/sessions/${sessionId}`, {
          headers: { "x-auth": this.token },
        })
        .then((res) => {
          if (res.data.status == "OK") {
            self.session = res.data.content;
            console.log(self.session);

            self.processAfterFetchData();
          } else {
            self.errorMessage = res.data.message;
          }
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    },
    async fetchUsers() {
      try {
        let res = await this.axios.get(
          this.$root.apiUser + "/users/list/" + this.$root.apiUserCustomId,
          {
            params: { showEnable: "true" },
          }
        );
        if (res.data.status == "OK") {
          this.users = res.data.content.items;
        }
      } catch (err) {
        console.log(err);
      }
    },
    callCancelSession() {
      if (!confirm("Xác nhận hủy đặt phòng?")) return;
      let self = this;
      this.axios
        .put(
          self.$root.apiAssetMana + "/sessions/locks",
          {
            ids: [this.session._id],
          },
          { headers: { "x-auth": this.token } }
        )
        .then((res) => {
          if (res.data.status == "OK") {
            alert("Hủy thành công!");
            self.fetchRentSession();
          } else {
            alert("Lỗi! " + res.data.message);
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          alert("Lỗi! " + err.mesage);
          console.log(err);
        });
    },
    callApproveSession() {
      let self = this;
      let payload = {
        confirmedBy: this.userId,
        ids: [this.session._id],
      };
      this.axios
        .put(self.$root.apiAssetMana + "/sessions/approves", payload, {
          headers: { "x-auth": this.token },
        })
        .then((res) => {
          if (res.data.status == "OK") {
            self.fetchRentSession();
          } else {
            alert("Lỗi! " + res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Lỗi! " + err.message);
        });
    },
    callRejectSession() {
      let self = this;
      let payload = {
        rejectedBy: this.userId,
        ids: [this.session._id],
      };
      this.axios
        .put(self.$root.apiAssetMana + "/sessions/rejects", payload, {
          headers: { "x-auth": this.token },
        })
        .then((res) => {
          if (res.data.status == "OK") {
            self.fetchRentSession();
          } else {
            alert("Lỗi! " + res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Lỗi! " + err.message);
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.params.session_id) {
        this.fetchRentSession();
        this.fetchUsers();
      }
    });
  },
};
</script>

<style>
.rent-inline-block {
  display: inline-block;
}
</style>
